var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap hs_code_pop",
      staticStyle: { width: "1300px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont pop_hscd_area" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.hsCdPop.001"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-bottom": "5px" } },
            [
              _c("span", { staticStyle: { color: "#000" } }, [
                _vm._v("HS CODE :"),
              ]),
              _vm._v("  "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.hscdRow,
                    expression: "hscdRow",
                  },
                ],
                staticClass: "wid50",
                attrs: { type: "text", maxlength: "3" },
                domProps: { value: _vm.hscdRow },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.hscdRow = $event.target.value
                    },
                    function ($event) {
                      return _vm.isNaNChk()
                    },
                  ],
                },
              }),
              _vm._v("  "),
              _c("span", { staticClass: "ml_auto cal_btn" }, [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.hscdAppendRow()
                      },
                    },
                  },
                  [_vm._v("+ row")]
                ),
              ]),
            ]
          ),
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.287")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.049")))]),
                _vm._v(" "),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.349"))),
                  _c("br"),
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.351"))),
                ]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.378")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.002")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.007")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.008")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.009")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.hsCdPop.010")))]),
                _vm._v(" "),
                _c("th"),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.hsCdList, function (vo, idx) {
                return _c("tr", { key: "trHsCd_" + idx }, [
                  _c("td", [_vm._v(_vm._s(idx + 1))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vo.cntrNo,
                          expression: "vo.cntrNo",
                        },
                      ],
                      attrs: {
                        id: "cntrNo_" + idx,
                        type: "text",
                        maxlength: "11",
                      },
                      domProps: { value: vo.cntrNo },
                      on: {
                        blur: function ($event) {
                          return _vm.checkCntrNo(idx)
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(vo, "cntrNo", $event.target.value)
                          },
                          function ($event) {
                            _vm.upperCntrNo($event, idx),
                              _vm.hideTooltip("cntrNo_" + idx)
                          },
                        ],
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "search_btn" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.imdunNo,
                            expression: "vo.imdunNo",
                          },
                        ],
                        staticClass: "inp_responsive_space3",
                        attrs: {
                          id: "imdunNo_" + idx,
                          type: "text",
                          autocomplete: "off",
                          maxlength: "4",
                        },
                        domProps: { value: vo.imdunNo },
                        on: {
                          keydown: function ($event) {
                            return _vm.delUno($event, idx)
                          },
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "imdunNo", $event.target.value)
                            },
                            function ($event) {
                              return _vm.delKorUno($event, idx)
                            },
                          ],
                        },
                      }),
                      _c("span", [
                        _c("button", {
                          staticClass: "search_btn01",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.getUnnoInfo(idx)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vo.imoClasCd,
                          expression: "vo.imoClasCd",
                        },
                      ],
                      attrs: {
                        id: "imoClasCd_" + idx,
                        type: "text",
                        readonly: "",
                      },
                      domProps: { value: vo.imoClasCd },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(vo, "imoClasCd", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-trans-string-check-input", {
                        attrs: {
                          id: "hsCd_" + idx,
                          "is-upper-case": true,
                          maxlength: "8",
                          "error-message": vo.hscdErrorMessage,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkHscd(idx)
                          },
                        },
                        model: {
                          value: vo.hsCd,
                          callback: function ($$v) {
                            _vm.$set(vo, "hsCd", $$v)
                          },
                          expression: "vo.hsCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(_vm._s(vo.hsNm)),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vo.blDscr,
                          expression: "vo.blDscr",
                        },
                      ],
                      attrs: { id: "blDscr_" + idx, type: "text" },
                      domProps: { value: vo.blDscr },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(vo, "blDscr", $event.target.value)
                          },
                          function ($event) {
                            return _vm.hideTooltip("blDscr_" + idx)
                          },
                        ],
                      },
                    }),
                  ]),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        attrs: {
                          "is-comma": true,
                          id: "pkgQty_" + idx,
                          maxlength: "10",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.hideTooltip("pkgQty_" + idx)
                          },
                        },
                        model: {
                          value: vo.pkgQty,
                          callback: function ($$v) {
                            _vm.$set(vo, "pkgQty", $$v)
                          },
                          expression: "vo.pkgQty",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.pkgCd,
                            expression: "vo.pkgCd",
                          },
                        ],
                        staticStyle: { width: "120px" },
                        attrs: { id: "pkgCd_" + idx },
                        on: {
                          input: function ($event) {
                            return _vm.hideTooltip("pkgCd_" + idx)
                          },
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              vo,
                              "pkgCd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBL200_P1.018"))),
                        ]),
                        _vm._l(_vm.pkgCdList, function (cvo, idx) {
                          return _c(
                            "option",
                            {
                              key: "pkgCd_" + idx,
                              domProps: { value: cvo.cd },
                            },
                            [_vm._v(_vm._s(cvo.cdNm))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("td", [
                    idx === 0
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addHsCd()
                              },
                            },
                          },
                          [_vm._v(" plus ")]
                        )
                      : _vm._e(),
                    idx !== 0
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.removeHsCd(idx)
                              },
                            },
                          },
                          [_vm._v(" minus ")]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.lang === "KOR",
                  expression: "lang === 'KOR'",
                },
              ],
              staticClass: "txt_desc",
            },
            [_vm._v("NON DG인 경우 UN NO. 기재하지 않아도 저장 가능합니다.")]
          ),
          _c("table", { staticClass: "tbl_row mt10" }, [
            _vm._m(1),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.019")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text_left" }, [
                  _c("span", [
                    _c("input", {
                      ref: "uploadFile",
                      staticStyle: { width: "100" },
                      attrs: { type: "file", id: "inpFile" },
                      on: {
                        change: function ($event) {
                          return _vm.fnFileUpload()
                        },
                      },
                    }),
                  ]),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelUpload.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX050G020.020")) + " "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelDownload("HsCodeTemplate.xlsx")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.021")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt20" }, [
          _c(
            "a",
            {
              staticClass: "button lg blue font_16",
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
          ),
          _c(
            "a",
            {
              staticClass: "button lg gray font_16",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.179")))]
          ),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePoup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "5%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "5%" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }