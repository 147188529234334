<template>
  <div class="popup_wrap hs_code_pop" style="width:1300px; height: 600px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont pop_hscd_area">
      <h1 class="page_title">{{ $t('msg.hsCdPop.001') }}</h1>
      <div class="content_box">
        <div style="text-align: right;margin-bottom: 5px;">
          <span style="color:#000;">HS CODE :</span>&nbsp;
          <input class="wid50"
                 v-model="hscdRow"
                 type="text"
                 maxlength="3"
                 @input="isNaNChk()"
          >&nbsp;
          <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="hscdAppendRow()">+ row</a></span>
        </div>
        <table class="tbl_col">
          <colgroup>
            <col width="5%">
            <col width="10%">
            <col width="10%">
            <col width="5%">
            <col width="10%">
            <col width="25%">
            <col width="10%">
            <col width="8%">
            <col width="12%">
            <col width="5%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th>{{ $t('msg.ONEX010P090.049') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBK100.349') }}<br>{{ $t('msg.CSBK100.351') }}</th> <!-- UN No.<br>Class -->
              <th>{{ $t('msg.CSBK100.378') }}</th> <!-- IMO CLASS -->
              <th>{{ $t('msg.hsCdPop.002') }}</th> <!-- HS CODE -->
              <th>{{ $t('msg.hsCdPop.007') }}</th> <!-- Cargo Description as per HS Code -->
              <th>{{ $t('msg.hsCdPop.008') }}</th> <!-- Cargo Description as per B/L -->
              <th>{{ $t('msg.hsCdPop.009') }}</th> <!-- NO OF PKG -->
              <th>{{ $t('msg.hsCdPop.010') }}</th> <!-- TYPE OF PKG -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in hsCdList" :key="'trHsCd_' + idx">
              <td>{{ idx + 1 }}</td>
              <td>
                <input
                  v-model="vo.cntrNo"
                  :id="'cntrNo_' + idx"
                  type="text"
                  @blur="checkCntrNo(idx)"
                  @input="upperCntrNo($event, idx), hideTooltip('cntrNo_' + idx)"
                  maxlength="11"
                >
              </td>
              <td>
              <div class="search_btn">
                 <input
                  v-model="vo.imdunNo"
                  :id="'imdunNo_' + idx"
                  type="text"
                  class="inp_responsive_space3"
                  autocomplete="off"
                  maxlength="4"
                  @keydown="delUno($event, idx)"
                  @input="delKorUno($event, idx)"
                >
                <span>
                  <button
                    class="search_btn01"
                    @click.prevent="getUnnoInfo(idx)"
                  />
                </span>
              </div>
              </td>
              <td>
                <input
                  v-model="vo.imoClasCd"
                  :id="'imoClasCd_' + idx"
                  type="text"
                  readonly
                >
              </td>
              <td>
                <e-trans-string-check-input :id="'hsCd_' + idx"
                                            v-model="vo.hsCd"
                                            :is-upper-case="true"
                                            maxlength="8"
                                            :error-message="vo.hscdErrorMessage"
                                            @input="checkHscd(idx)"
                >
                </e-trans-string-check-input>
              </td>
              <td class="text_left">{{ vo.hsNm }}</td>
              <td>
                <input
                  v-model="vo.blDscr"
                  :id="'blDscr_' + idx"
                  type="text"
                  @input="hideTooltip('blDscr_' + idx)"
                >
              </td>
              <td>
                <e-input-number
                  v-model="vo.pkgQty"
                  :is-comma="true"
                  :id="'pkgQty_' + idx"
                  maxlength="10"
                  @input="hideTooltip('pkgQty_' + idx)"
                >
                </e-input-number>
              </td>
              <td>
                <select
                  v-model="vo.pkgCd"
                  :id="'pkgCd_' + idx"
                  style="width: 120px;"
                  @input="hideTooltip('pkgCd_' + idx)"
                >
                  <option value="">{{ $t('msg.CSBL200_P1.018') }}</option>
                  <option v-for="(cvo, idx) in pkgCdList" :key="'pkgCd_' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select></td>
              <td>
                <button
                  v-if="idx === 0"
                  type="button"
                  class="tbl_icon plus"
                  @click="addHsCd()"
                >
                  plus
                </button>
                <button
                  v-if="idx !== 0"
                  type="button"
                  class="tbl_icon minus"
                  @click="removeHsCd(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-show="lang === 'KOR'" class="txt_desc">NON DG인 경우 UN NO. 기재하지 않아도 저장 가능합니다.</p>

        <table class="tbl_row mt10">
          <colgroup>
            <col width="5%">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX050G020.019') }}</th> <!-- 파일 -->
              <td class="text_left">
                <span>
                <!--
                  <div class="div-file">
                    <button @click="handleInputDivClick">{{ $t('msg.ONEX050G020.023') }}</button>
                    <span style="padding-right: 10px;">{{ filename }}</span>
                  </div>-->
                  <input ref="uploadFile" @change="fnFileUpload()" type="file" id="inpFile" style="width: 100;">
                </span>
                <span>
                  <a class="button sm" href="#" @click.prevent="excelUpload">
                    {{ $t('msg.ONEX050G020.020') }}
                  </a> <!-- Excel Upload -->
                  <a class="button sm" href="#" @click.prevent="excelDownload('HsCodeTemplate.xlsx')">{{ $t('msg.ONEX050G020.021') }}</a> <!-- Excel Download -->
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text_center mt20">
        <a class="button lg blue font_16" @click="save()">{{ $t('msg.CSBK100.178') }}</a>
        <a class="button lg gray font_16" @click="$emit('close')">{{ $t('msg.CSBK100.179') }}</a>
      </div>
    </div>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePoup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import EInputNumber from '@/components/common/EInputNumber'
import ETransStringCheckInput from '@/components/trans/ETransStringCheckInput'
import bookings from '@/api/rest/trans/bookings'

export default {
  name: 'HsCodeIDPop',
  components: {
    EInputNumber,
    ETransStringCheckInput,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'unno-pop': () => import('@/pages/trans/popup/UnnoPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          blNo: '',
          ldCatCd: '',
          uiId: '',
          ctrCd: '',
          pkgCd: '',
          pkgQty: '',
          containerList: []
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      hsCdList: [],
      customComponent: null,
      popParams: {},
      selectFunc: null,
      pkgCdList: [],
      lang: localStorage.getItem('service_lang'),
      filename: '',
      uploadParams: [],
      tempFile: {
        id: '',
        msg: ''
      },
      hscdRow: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const arrCdId = ['01130']
      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res
      })

      const params = {
        blNo: this.parentInfo.blNo,
        ldCatCd: this.parentInfo.ldCatCd,
        ctrCd: this.parentInfo.ctrCd
      }
      await trans.findHsCd(params).then(res => {
        const result = res.data
        if (res.headers.respcode === 'C0000' && res.data !== undefined) {
          const pkgCdList = this.commonCodes['01130']

          result.forEach(c => {
            const pkgCdInfo = pkgCdList.filter(vo => vo.cd === c.pkgCd)
            if (pkgCdInfo !== undefined && pkgCdInfo.length > 0) {
              c.pkgCd = pkgCdInfo[0].cd
            }
          })

          this.hsCdList = result
          pkgCdList.sort((a, b) => {
            return a.cdNm < b.cdNm ? -1 : a.cdNm > b.cdNm ? 1 : 0
          })
        this.pkgCdList = pkgCdList
        console.log('pkg->', this.pkgCdList)
        }
      })
      //입력화면에서 넘어온 컨테이너 정보 추가.
      this.parentInfo.containerList.forEach((c, i) => {
        let addRow = true
        this.hsCdList.forEach(e => {
          if (c.cntrNo === e.cntrNo || this.$ekmtcCommon.isEmpty(c.cntrNo)) {
            addRow = false
          }
        })
        if (addRow) {
          this.addHsCd(this.parentInfo.containerList[i])
        }
      })

     //this.hsCdList.forEach((c, i) => {
      for (let i = this.hsCdList.length - 1; i >= 0; i--) {
          const cntrOk = this.parentInfo.containerList.some((e, j) => {
            return e.cntrNo === this.hsCdList[i].cntrNo
          })
          this.checkCntrNo(i)
          if (!cntrOk) {
           // this.hsCdList.splice(i, 1)
          }
      }
      this.hsCdList.sort((a, b) => {
       return a.cntrNo > b.cntrNo ? -1 : 1
      })
      this.hsCdList.sort((a, b) => {
       return this.$ekmtcCommon.isEmpty(a.hsCd) && this.$ekmtcCommon.isNotEmpty(b.hsCd) ? 1 : -1
      })

      if (this.hsCdList.length === 0) {
        this.addHsCd()
      }
    },
    addHsCd (cntr) {
      const hsCdList = this.hsCdList
      const seq = hsCdList.length === 0 ? 1 : Number(hsCdList[hsCdList.length - 1].seq) + 1
      const obj = {
        seq: seq,
        cntrNo: this.$ekmtcCommon.isNotEmpty(cntr) ? cntr.cntrNo : '',
        imdunNo: '',
        imoClasCd: '',
        imdgSeq: '',
        hsCd: '',
        hsNm: '',
        blDscr: '',
        pkgQty: this.$ekmtcCommon.isNotEmpty(cntr) ? cntr.pkgQty : '',
        pkgCd: this.parentInfo.pkgCd,
        cgoTypCd: this.$ekmtcCommon.isNotEmpty(cntr) ? cntr.cgoTypCd : '',
        imdunNoAgg: '',
        hscdErrorMessage: ''
      }
     this.hsCdList.push(obj)
    },
    removeHsCd (idx) {
      const div = document.querySelector('.hs_code_pop')
      const selector = div.querySelector('#cntrNo_' + idx)
      this.$ekmtcCommon.hideErrorTooltip(selector)
      const hsCdList = this.hsCdList

      hsCdList.splice(idx, 1)
    },
    getUnnoInfo (idx) {
     var selector = document.querySelector('#imdunNo_' + idx)
     this.$ekmtcCommon.hideErrorTooltip(selector)
     this.popParams = {
        selectIdx: idx
      }
      this.selectFunc = this.setUnnoInfo
      this.openPopup('unno-pop')
    },
    setUnnoInfo (vo) {
      const selectIdx = vo.selectIdx
      const hsCdInfo = this.hsCdList[selectIdx]

      if (vo !== undefined) {
        hsCdInfo.imdunNo = vo.imdunNo
        hsCdInfo.imoClasCd = vo.imoClasCd
        hsCdInfo.imdgSeq = vo.imdgSeq
      } else {
        hsCdInfo.imdunNo = ''
        hsCdInfo.imoClasCd = ''
        hsCdInfo.imdgSeq = ''
      }
    },
    async checkCntrNo (idx) {
      const hsCdInfo = this.hsCdList[idx]

      if (this.$ekmtcCommon.isNotEmpty(hsCdInfo.cntrNo)) {
        const params = {
          cntrNo: hsCdInfo.cntrNo,
          uiId: this.parentInfo.uiId,
          blNo: this.parentInfo.blNo
        }

        let succFlag = true
        await trans.findHsCdCntrNo(params).then(res => {
          const result = res.data
          if (res.headers.respcode === 'C0000' && result !== undefined && this.$ekmtcCommon.isNotEmpty(result)) {
            //hsCdInfo.pkgQty = result.pkgQty || hsCdInfo.pkgQty
            hsCdInfo.pkgCd = result.pkgCd
            const pkgCdList = this.commonCodes['01130']
            const pkgCdInfo = pkgCdList.filter(vo => vo.cd === result.pkgCd)
            if (pkgCdInfo !== undefined && pkgCdInfo.length > 0) {
              //this.$set(hsCdInfo, 'pkgCdNm', pkgCdInfo[0].cdNm)
              this.$set(hsCdInfo, 'pkgCd', pkgCdInfo[0].cd)
            } else if (this.parentInfo.pkgCd !== undefined && this.$ekmtcCommon.isNotEmpty(this.parentInfo.pkgCd)) {
              this.$set(hsCdInfo, 'pkgCd', this.parentInfo.pkgCd)
            }

            hsCdInfo.cgoTypCd = result.cgoTypCd
            hsCdInfo.imdunNoAgg = result.imdunNoAgg
          } else {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (!succFlag) {
           // hsCdInfo.pkgQty = ''
            //hsCdInfo.pkgCd = ''
            //hsCdInfo.pkgCdNm = ''
           // hsCdInfo.cgoTypCd = ''
           // hsCdInfo.imdunNoAgg = ''
          }
        })
      } else {
        hsCdInfo.pkgQty = ''
        hsCdInfo.pkgCd = ''
        hsCdInfo.pkgCdNm = ''
        hsCdInfo.cgoTypCd = ''
        hsCdInfo.imdunNoAgg = ''
      }
    },
    upperCntrNo (e, idx) {
      const value = e.target.value
      e.target.value = value.replace(/[^a-zA-Z0-9]/gi, '')
    },
    delUno (e, idx) {
      var imdunNo = document.querySelector('#imdunNo_' + idx)
      var imnClassCd = document.querySelector('#imoClasCd_' + idx)
      var selector = document.querySelector('#imdunNo_' + idx)
      this.$ekmtcCommon.hideErrorTooltip(selector)
      const hsCdInfo = this.hsCdList[idx]
      if (e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 46) {
        if (e.keyCode === 8 || e.keyCode === 46) {
          e.target.value = ''
          hsCdInfo.imdunNo = ''
          hsCdInfo.imoClasCd = ''
          hsCdInfo.imdgSeq = ''
          imnClassCd.value = ''
        }
      } else {
        e.preventDefault()
      }
    },
    delKorUno (e, idx) {
      var selector = document.querySelector('#imdunNo_' + idx)
      this.$ekmtcCommon.hideErrorTooltip(selector)
      e.target.value = e.target.value.replace(/[^0-9]/g, '')
    },
    checkHscd (idx) {
      const hsCdInfo = this.hsCdList[idx]
      const hsCd = hsCdInfo.hsCd

      const isOk = this.checkValidation('hsCd', idx)
      if (!isOk) {
        return
      }
      transCommon.getHscdValidation({
        hsCd: hsCd,
        hsFlag: this.parentInfo.ctrCd,
        hsNmSearchYn: 'Y'
      }).then(res => {
        const result = res.data
        if (result.returnFlag === 'N') {
          this.$set(this.hsCdList[idx], 'hscdErrorMessage', this.$t('msg.CSBL200.175'))
          this.$set(this.hsCdList[idx], 'succYn', 'N')
          this.$set(this.hsCdList[idx], 'hsNm', '')
        } else {
          if (this.$ekmtcCommon.isNotEmpty(result.hsNm)) {
            this.$set(this.hsCdList[idx], 'hsNm', result.hsNm)
          }
          this.$set(this.hsCdList[idx], 'hscdErrorMessage', '')
          this.$set(this.hsCdList[idx], 'succYn', 'Y')
          this.$set(this.hsCdList[idx], 'hscdError', '')
        }
      })
    },
    checkValidation (flag, idx, saveFlag) {
      const hsCdInfo = this.hsCdList[idx]
      let isOk = true
      hsCdInfo.hscdErrorMessage = ''

      const div = document.querySelector('.hs_code_pop')
      const selector = div.querySelector('#' + flag + '_' + idx)
      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (flag === 'cntrNo') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else if (flag === 'imdunNo') {
        if (hsCdInfo.cgoTypCd === '01' && this.$ekmtcCommon.isEmpty(selector.value)) {
          //this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          //isOk = false
        } else if (hsCdInfo.cgoTypCd === '01' && this.$ekmtcCommon.isNotEmpty(hsCdInfo.imdunNoAgg)) {
          if (hsCdInfo.imdunNoAgg.indexOf(selector.value) === -1) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.349') + this.$t('tem.CMAT006'))
            isOk = false
          }
        }
      } else if (flag === 'hsCd') {
        if (this.$ekmtcCommon.isNotEmpty(hsCdInfo.hsCd.trim())) {
          if (hsCdInfo.hsCd.length !== 4 && hsCdInfo.hsCd.length !== 6 && hsCdInfo.hsCd.length !== 8) {
            if (saveFlag === 'S') {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.175'))
            } else {
              hsCdInfo.hscdErrorMessage = this.$t('msg.CSBL200.175')
            }
            hsCdInfo.succYn = 'N'
            isOk = false
          }
          /*
          this.hsCdList.forEach((h, i) => {
            if (i !== idx && h.hsCd === hsCdInfo.hsCd) {
              hsCdInfo.hscdErrorMessage = this.$t('msg.hsCdPop.005')
              isOk = false
            }
          })
          */
        } else {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        }
      } else if (flag === 'pkgCd') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          hsCdInfo.pkgCd = this.parentInfo.pkgCd
        }
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }
      return isOk
    },
    checkValidationAll () {
      let isOk = true
      let cntrAdd = []
      const arrEl = ['hsCd', 'blDscr', 'pkgQty', 'pkgCd', 'cntrNo']

      this.hsCdList.forEach((h, i) => {
         const hsCdInfo = this.hsCdList[i]
          const div = document.querySelector('.hs_code_pop')
          const selector = div.querySelector('#imdunNo_' + i)
            if (hsCdInfo.cgoTypCd === '01' && this.$ekmtcCommon.isNotEmpty(selector.value)) {
              let cntObj = {
                cntrNo: hsCdInfo.cntrNo,
                idx: i
              }
              cntrAdd.push(hsCdInfo.cntrNo)
            }
      })
      this.hsCdList.forEach((h, i) => {
          const hsCdInfo = this.hsCdList[i]
          const div = document.querySelector('.hs_code_pop')
          const selector = div.querySelector('#imdunNo_' + i)
          const cntrNo = div.querySelector('#cntrNo_' + i)
            if (hsCdInfo.cgoTypCd === '01' && this.$ekmtcCommon.isNotEmpty(selector.value) && this.$ekmtcCommon.isNotEmpty(hsCdInfo.imdunNoAgg)) {
              if (hsCdInfo.imdunNoAgg.indexOf(selector.value) === -1) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.349') + this.$t('tem.CMAT006'))
                isOk = false
              }
            }
            if (hsCdInfo.cgoTypCd === '01' && this.$ekmtcCommon.isEmpty(selector.value) && cntrAdd.indexOf(cntrNo.value) === -1) {
             this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
             isOk = false
           }
        })

        arrEl.forEach(el => {
        this.hsCdList.forEach((h, i) => {
          if (!this.checkValidation(el, i, 'S')) {
            isOk = false
          }
        })
      })
      return isOk
    },
    openPopup (compNm) {
      this.customComponent = null
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.hs_code_pop > .popup_dim')
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.hs_code_pop > .popup_dim')
    },
    save () {
      let isOk = this.checkValidationAll()
      let sumPkgQty = 0
      let pkgCd = true
      let hsCd = true
      let imdunNo = true
      this.hsCdList.forEach((h, i) => {
        const div = document.querySelector('.hs_code_pop')
        const selector = div.querySelector('#pkgCd_' + i)
        if (h.succYn === 'N' || h.hscdError === 'Error') {
          isOk = false
          hsCd = false
          this.$ekmtcCommon.showErrorTooltip(div.querySelector('#hsCd_' + i), this.$t('msg.CSBL200.175'))
        }
        sumPkgQty += Number(h.pkgQty)
        if (h.pkgCd !== this.parentInfo.pkgCd) {
          pkgCd = false
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200_P1.021'))
        }
        if (h.imdunNoError === 'Error' && this.$ekmtcCommon.isNotEmpty(h.imdunNo)) {
          isOk = false
          imdunNo = false
          this.$ekmtcCommon.showErrorTooltip(div.querySelector('#imdunNo_' + i), this.$t('msg.CSBL200.282'))
        }
      })
      //HS 코드 유효성
      if (!hsCd) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.175'))
        return false
      }
            //UN No. 유효성 체크
      if (!imdunNo) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.282'))
        return false
      }
      //B/L 이랑 일치하는지 체크
      if (sumPkgQty !== Number(this.parentInfo.pkgQty)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.276'))
        return false
      }
      if (!pkgCd) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.021'))
        return false
      }
      //컨테이너 일치 체크.
      let sliceRow = true
      this.hsCdList.forEach((c, i) => {
        const div = document.querySelector('.hs_code_pop')
        const selector = div.querySelector('#cntrNo_' + i)
        const cntrOk = this.parentInfo.containerList.some((e) => {
          return e.cntrNo === c.cntrNo
        })
        if (!cntrOk) {
          sliceRow = false
          if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200_P1.021'))
          }
        }
      })
      if (!sliceRow) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.021'))
        return false
      }

      if (isOk) {
        const regParams = {
          blNo: this.parentInfo.blNo,
          ldCatCd: this.parentInfo.ldCatCd,
          hsCdList: this.hsCdList
        }
        let succFlag = true
        trans.saveHsCdMulti(regParams).then(res => {
          if (res.headers.respcode !== 'C0000' || res.data === 'FAIL') {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag) {
            const TH = this
            this.$ekmtcCommon.alertCallback(this.$t('art.CMATK383'), () => {
              let qty = 0

              this.hsCdList.forEach(vo => {
                qty += Number(vo.pkgQty)
              })

              TH.$emit('selectFunc', qty)
              TH.$emit('close')
            })
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
          }
        })
      }
    },
    hideTooltip (id) {
      const selector = document.querySelector('#' + id)
      this.$ekmtcCommon.hideErrorTooltip(selector)
    },
    fnFileUpload () {
      //file 타입 태그 클릭 이벤트 -> change이벤트 호출 -> 임시저장 -> excel upload 클릭시 file아이디 보내서 받은 것을 바이트스트림으로 변환후 받기
      const formData = new FormData()
      const inpFile = document.querySelector('#inpFile')

      formData.append('files', inpFile.files[0])
      this.filename = inpFile.files[0].name

      commons.tempFileUpload(formData).then((result) => {
          const fileId = result.data.fileId
          this.tempFile.id = fileId
          this.tempFile.msg = 'SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';'
      })
      .catch(err => {
        console.log(err)
      })
    },
    handleInputDivClick () {
      this.$refs.uploadFile.click()
    },
    //엑셀 업로드
    async excelUpload () {
      let fileName = document.getElementById('inpFile').value

      //기본 벨리데이션
      if (!fileName) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK169')) //등록할 파일을 선택하세요.
        return
      }
      if (!new RegExp('xls$', 'i').test(fileName) && !new RegExp('xlsx$', 'i').test(fileName)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.039')) //Please choose one of the excel files. [.xls]
      } else {
        //엑셀 데이터 읽은 후 vgm 셋팅
        let params = {
                      fileId: this.tempFile.id,
                      methCd: this.parentInfo.methCd
                     }
        const result = await trans.hsCdExcelUpload(params)
        if (result.data.xlsErrorYn === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.044')) // 잘못된 양식입니다.
        } else if (result.data.xlsErrorYn === 'A') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CCIT100.034')) // 등록된 내용이 없는경우.
        } else {
          this.hsCdList = []
          this.hsCdList = result.data.hscd
          this.hsCdList.forEach((c, i) => {
            c.pkgCd = this.parentInfo.pkgCd
            this.hsCdList[i] = c
          })
          this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015')) //적용되었습니다
        }
      }
    },
    async excelDownload (fileName) {
      const result = await bookings.excelDownload(fileName)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    hscdAppendRow () {
      for (let i = 0; i < this.hscdRow; i++) {
        this.addHsCd()
      }
      //this.hscdRow = ''
      this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015')) //적용되었습니다
    },
    isNaNChk () {
      let vNormal = /[^0-9.]/g
      let val = this.hscdRow
      if (vNormal.test(val) || isNaN(parseInt(val))) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.317')) //숫자만 입력하세요.
        this.hscdRow = ''
      }
    }
  }
}
</script>
